window.loadPaginatedResults = function(targetId, url, startRow, totalRows, rowsPerPage, async) {
  document.getElementById(targetId).innerHTML = '<img src="/images/loading_24x24.gif" alt="Loading..." class="loading" />';
  // Parse the URL and append query parameters
  let urlObj = new URL(url);
  let params = new URLSearchParams(urlObj.search);
  params.set('start_row', startRow);
  params.set('total_rows', totalRows);
  params.set('rows_per_page', rowsPerPage);
  urlObj.search = params.toString();

  fetch(urlObj.toString(), {
    method: 'GET',
    headers: {
      'Content-Type': 'text/html'
    }
  })
  .then(response => response.text())
  .then(result => {
    document.getElementById(targetId).innerHTML = result;
    if(targetId === 'gmUserSearchResults') {
      buildSortTree();
    };
    try {
      CyberGrants.initQuickView();
    } catch (error) {
      console.warn('error in initQuickView()');
    }
  })
  .catch(error => {
    console.error('Error:', error);
  });
}

window.buildSortTree = function() {
  if (TableKit.tables['userTable']) {
    TableKit.tables['userTable'].dom = {head:null,rows:null,cells:{}}; // just nuke the cache'||chr(10)||
  };
  TableKit.Sortable.init('userTable', 
    {
      sortFirstAscendingClass: 'js-sort-first-asc',
      columnClass: 'js-sort-col',
      descendingClass: 'js-sort-asc',
      ascendingClass: 'js-sort-desc'
    }
  )
};

document.addEventListener('DOMContentLoaded', function() {
  document.body.addEventListener('click', function(e) {
    if (e.target.matches('a.js-pagination')) {
      e.preventDefault();
      var targetId = e.target.dataset.targetId;
      var startRow = e.target.dataset.startRow;
      var totalRows = e.target.dataset.totalRows;
      var rowsPerPage = e.target.dataset.rowsPerPage;
      var asynchronous = e.target.dataset.asynchronous;

      loadPaginatedResults(targetId, e.target.href, startRow, totalRows, rowsPerPage, asynchronous);
    };
  });
});